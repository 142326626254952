import Layout1 from '@/layout/Layout1'

export default [{
  path: '/',
  component: Layout1,
  children: [
    {
      path: 'dashboard',
      component: () => import('@components/client/Dashboard')
    },
    {
      path: 'alerts',
      component: () => import('@components/client/Alerts')
    },
    {
      path: 'alert/',
      component: () => import('@components/client/AlertsSingle')
    },
    {
      path: 'alert/:id',
      component: () => import('@components/client/AlertsSingle')
    },
    {
      path: 'clients',
      component: () => import('@components/admin/Clients')
    },
    {
      path: 'client',
      component: () => import('@components/admin/ClientSingle')
    },
    {
      path: 'client/:id',
      component: () => import('@components/admin/ClientSingle')
    },
    {
      path: 'team',
      component: () => import('@components/admin/Team')
    },
    {
      path: 'profile',
      component: () => import('@components/admin/Profile')
    }
  ]
}]
